import "./src/styles/style.css";
import { navigate } from "gatsby";
//import { datadogRum } from "@datadog/browser-rum";
import React from "react";
import RootElement from "./src/components/root-element";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";
import "@fontsource-variable/inter";
import "@fontsource-variable/montserrat";
import "@fontsource-variable/source-sans-3";

declare global {
  interface Window {
    gtmScriptAdded?: boolean;
    osanoScriptAdded?: boolean;
    convertScriptAdded?: boolean;
    chiliChatScriptAdded?: boolean;
    // zoomInfoScriptAdded?: boolean;
    ChiliChat?: {
      config: {
        workspaceId: string;
        tenantId: string;
        chatUrl: string;
      };
    };
  }
}

export const wrapRootElement = ({ element }) => {
  return <RootElement>{element}</RootElement>;
};

export const onClientEntry = () => {
  if (!window.osanoScriptAdded) {
    const script = document.createElement("script");
    script.src =
      "https://cmp.osano.com/169xuqUPUjoZK3hiz/70aba732-bc5d-4f8e-b4de-e7e033ff0a61/osano.js";
    script.async = true;
    document.head.appendChild(script);
    window.osanoScriptAdded = true;
  }

  if (!window.convertScriptAdded) {
    const convertScript = document.createElement("script");
    convertScript.src = "//cdn-4.convertexperiments.com/v1/js/10034870-10034425.js?environment=production";
    convertScript.type = "text/javascript";
    convertScript.async = true;
    document.head.appendChild(convertScript);
    window.convertScriptAdded = true;
  }

  if (!window.chiliChatScriptAdded) {
    // Initialize ChiliChat config directly
    window.ChiliChat = {
      config: {
        workspaceId: "31fcce39-a0d7-450e-8f1b-ea27bcb8a5c5",
        tenantId: "safeguardglobal",
        chatUrl: "https://safeguardglobal.chilipiper.com/chat/widget/",
      }
    };

    // Add the main ChiliChat script
    const chiliChatScript = document.createElement("script");
    chiliChatScript.src = "https://safeguardglobal.chilipiper.com/chat/embed/assets/index.js";
    chiliChatScript.type = "module";
    chiliChatScript.crossOrigin = "anonymous";
    chiliChatScript.defer = true;
    
    // Make sure script is loaded after config is set
    chiliChatScript.onload = () => {
      // Initialize the chat widget
      const chatContainer = document.createElement("div");
      chatContainer.id = "chili-chat-container";
      document.body.appendChild(chatContainer);

      // Try to initialize the chat if an init function exists
      if (typeof (window as any).initChiliChat === "function") {
        (window as any).initChiliChat();
      }
    };

    chiliChatScript.onerror = (error) => {
      console.error("Error loading ChiliChat script:", error);
    };
    
    // Append to body instead of head for module scripts
    document.body.appendChild(chiliChatScript);
    window.chiliChatScriptAdded = true;
  }
};

export const onPreRouteUpdate = ({ location, prevLocation }) => {
  const pathParts = location.pathname.split("/");
  const currentLocale = pathParts[1];
  const supportedLocales = ["es", "fr", "de", "zh"];
  if (supportedLocales.includes(currentLocale)) {
    // If the current path already includes a supported locale, do nothing
    return;
  } else {
    if (prevLocation) {
      const prevPathParts = prevLocation.pathname.split("/");
      const prevLocale = prevPathParts[1].split("-")[0];
      if (supportedLocales.includes(prevLocale)) {
        const newPath = `/${prevLocale}${location.pathname}`;
        return navigate(newPath, { replace: true });
      }
    }
  }
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  // GTM code for culture page
  const GTM_ID_TEST = "GTM-K872NKBR";
  if (process.env.REACT_APP_ENVIRONMENT == "production") {
    if (location.pathname.includes("/culture")) {
      if (!window.gtmScriptAdded) {
        (function (w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
          const f = d.getElementsByTagName(s)[0],
            j: any = d.createElement(s),
            dl = l !== "dataLayer" ? "&l=" + l : "";
          j.async = true;
          j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
          f.parentNode!.insertBefore(j, f);
        })(window, document, "script", "dataLayer", GTM_ID_TEST);
        // Set a flag to avoid adding the script on every routeUpdate
        window.gtmScriptAdded = true;
      }
    }
  }
};
